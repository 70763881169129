<style lang="scss">
@import "styles/reset";
@import url("https://use.typekit.net/vxw1lkr.css");
@import "styles/transitions";
@import "styles/utils";

#app {
  font-family: "alternate-gothic-atf", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondary-dark;
  overflow: hidden;
  position: relative;
  //background-color: black;
}

#flash-bag {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  text-align: center;
  color: white;
  background-color: $primary-dark;
  z-index: 10;
}

p {
  line-height: 1.25rem;
}

.page-layout {
  height: calc(calc(var(--vh, 1vh) * 100 - 48px));
  width: 100vw;
  position: relative;
}

.swiper-container-layout {
  height: inherit;
  width: inherit;
  z-index: 0;
  position: relative;
  overflow: hidden;
}
</style>

<template>
  <!-- cookie consent bar -->
  <cookie-consent></cookie-consent>
  <!-- flash bag -->
  <!--  <transition name="slideInOut">-->
  <!--    <div id="flash-bag" v-if="GStore.flashMessage">-->
  <!--      {{ GStore.flashMessage }}-->
  <!--    </div>-->
  <!--  </transition>-->
  <!-- nav -->
  <nav-bar />
  <!-- nav menu -->
  <nav-menu />
  <!-- main -->
  <div class="page-layout">
    <ken-burns-carousel
      :images="images"
      direction="top-right"
      interval="8000"
      clip
    />
    <div class="swiper-container swiper-container-layout">
      <div class="swiper-wrapper swiper-wrapper-layout">
        <landing-slide
          class="swiper-slide swiper-slide-layout"
          data-history="landing"
        />
        <about-slide
          class="swiper-slide swiper-slide-layout"
          data-history="about"
        />
        <food-slide
          class="swiper-slide swiper-slide-layout"
          data-history="food"
        />
        <drink-slide
          class="swiper-slide swiper-slide-layout"
          data-history="drink"
        />
        <place-slide
          class="swiper-slide swiper-slide-layout"
          data-history="place"
        />
        <footer-slide
          class="swiper-slide swiper-slide-layout"
          data-history="footer"
        />
      </div>
    </div>
  </div>
  <!-- modals -->
  <modal-view>
    <router-view v-slot="{ Component }">
      <transition
        @before-enter="slideScaleBeforeEnter"
        @enter="slideScaleEnter"
        @leave="slideScaleLeave"
      >
        <component :is="Component" />
      </transition>
    </router-view>
  </modal-view>
</template>

<script>
// import { setMetaInfo } from "@/js/meta-info";
// const metaInfo = {
//   lang: "fr",
//   title: "Patrol Paris",
//   description:
//     "Restaurant Patrol Paris situé au coeur du 6eme arrondissement de Paris. - 2 Place Edmond Rostand 75006 PARIS - Tel: +33(0)1 53 19 82 70 - facebook.com/patrolparis - instagram.com/patrol_paris"
// };

import { defineAsyncComponent } from "vue";
import { slideScale, zoom } from "@/js/gsap-transitions-hooks";

import NavMenu from "@/views/nav/NavMenu";
import CookieConsent from "@/components/CookieConsent";
import KenBurnsCarousel from "@/components/KenBurnsCarousel";

import LandingSlide from "@/views/Landing";
import AboutSlide from "@/views/About";
import FoodSlide from "@/views/Food";
import DrinkSlide from "@/views/Drink";
import PlaceSlide from "@/views/Place";
import FooterSlide from "@/views/Footer";

import {
  Swiper,
  Mousewheel,
  EffectCoverflow,
  EffectFade,
  History
} from "swiper";

Swiper.use([Mousewheel, EffectCoverflow, EffectFade, History]);
import "swiper/swiper-bundle.css";

export default {
  // beforeCreate() {
  //   setMetaInfo(metaInfo);
  // },
  data: () => ({
    images: [
      "https://ik.imagekit.io/kreatikart/patrol_paris/interior-bar-3-min.jpg",
      "https://ik.imagekit.io/kreatikart/patrol_paris/interior-min.jpg",
      "https://ik.imagekit.io/kreatikart/patrol_paris/brunch-table-min.jpg",
      "https://ik.imagekit.io/kreatikart/patrol_paris/food-table-min.jpg"
    ]
  }),
  // inject: ["GStore"],
  components: {
    // CookieConsent
    CookieConsent,
    // Navigation
    NavBar: defineAsyncComponent(() => import("@/views/nav/NavBar")),
    NavMenu,
    // Modals
    ModalView: defineAsyncComponent(() => import("@/views/modal/ModalView")),
    // Pages
    LandingSlide,
    AboutSlide,
    FoodSlide,
    DrinkSlide,
    PlaceSlide,
    FooterSlide,
    // Carousel
    KenBurnsCarousel
  },
  methods: {
    ...slideScale,
    ...zoom
  },
  computed: {
    getSwiperProgress() {
      return this.$store.getters["getSwiperProgress"];
    },
    vh() {
      return window.innerHeight * 0.01;
    }
  },
  mounted() {
    const calcVerticalHeight = () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      );
    };
    calcVerticalHeight();
    window.addEventListener("resize", calcVerticalHeight);
    // Swiper init
    new Swiper(".swiper-container-layout", {
      speed: 800,
      // spaceBetween: "100%",
      // spaceBetween: 2,
      mousewheel: {
        releaseOnEdges: false
      },
      followFinger: false,
      direction: "vertical",
      history: {
        replaceState: true,
        key: ""
      },
      // preventInteractionOnTransition: true,
      // breakpoints: {
      //   "@1.0": {
      //     // direction: "horizontal"
      //     allowTouchMove: false
      //   }
      // },
      on: {
        init: swiper => {
          this.$store.dispatch("swiper/registerSwiper", swiper);
        },
        slideChange: swiper => {
          this.$store.commit("swiper/SET_INDEX", { index: swiper.activeIndex });
        },
        progress: (swiper, progress) => {
          if (progress === 0) {
            this.$store.commit("nav/BLEND_NAV", { value: false });
          } else {
            this.$store.commit("nav/BLEND_NAV", { value: true });
          }
        }
      }
    });
  }
};
</script>
"
